import React from 'react';
import { appReasons } from '../../../data/app-reasons';
import { PawIcon } from '../../../icons/PawIcon';
import { PawWithLineIcon } from '../../../icons/PawWithLineIcon';
import { Box } from '../../../wag-react/next-components/box';
import { Flex } from '../../../wag-react/next-components/Flex';
import { Image } from '../../../wag-react/core-components/image';
import { Typography } from '../../../wag-react/components/Typography';
const isLastItemFromArray = (index: number, arr: Array<unknown>) => index === arr.length - 1;
export const AppReasonsToWagSection = () => <Box mt={['40px', '80px']} data-sentry-element="Box" data-sentry-component="AppReasonsToWagSection" data-sentry-source-file="AppReasonsToWagSection.tsx">
    {appReasons.map((reason, index) => <Flex key={reason.heading} flexDirection={['column', 'row']} alignItems="center" justifyContent={['unset', 'center']} maxHeight={['100%', '140px']} mb={[0, 4]} mx={[0, 4]}>
        <Image src={reason.iconURL} alt={`reason-image-${index}`} width={['120px']} height={['120px']} />

        <Box display={['none', 'block']}>
          <Box as={isLastItemFromArray(index, appReasons) ? PawIcon : PawWithLineIcon} width="32px" height="168px" mt={isLastItemFromArray(index, appReasons) ? 0 : '136px'} mx="40px" />
        </Box>

        <Flex flexDirection={['column']} alignItems={['center', 'start']} width={[1, '432px']} my={[3, 0]}>
          <Typography m={0} mb={[0, 3]} fontSize={['18px', '24px', '30px']} fontWeight="700" color="medGray" lineHeight={[0, '22px']} textAlign={['center', 'unset']}>
            {reason.heading}
          </Typography>
          <Typography m={0} mt={[1, 0]} fontSize={['16px', '18px']} color="medGray" lineHeight={[0, '24px']} textAlign={['center', 'unset']}>
            {reason.subHeading}
          </Typography>
        </Flex>
      </Flex>)}
  </Box>;