import React from 'react';
import { SvgIcon, SvgIconProps } from '../wag-react/next-components/icons';
export type PawWithLineIconProps = SvgIconProps;
export const PawWithLineIcon = (props: PawWithLineIconProps) => <SvgIcon viewBox="0 0 34.014 167" {...props} data-sentry-element="SvgIcon" data-sentry-component="PawWithLineIcon" data-sentry-source-file="PawWithLineIcon.tsx">
    <g data-sentry-element="g" data-sentry-source-file="PawWithLineIcon.tsx">
      <path d="M17.424 50v116" fill="none" stroke="#00bf8f" strokeLinecap="round" strokeWidth="2" data-sentry-element="path" data-sentry-source-file="PawWithLineIcon.tsx" />
      <g fill="#00bf8f" data-sentry-element="g" data-sentry-source-file="PawWithLineIcon.tsx">
        <path d="M27.589 22.978a7.867 7.867 0 01-3.414-2.213 10.783 10.783 0 01-1.879-4.083c-.69-2.347-1.353-4.539-5.092-4.539h-.338c-3.758 0-4.4 2.206-5.092 4.539a11.31 11.31 0 01-1.879 4.083 7.88 7.88 0 01-3.41 2.215c-1.963.8-4.011 1.647-4.011 5.444 0 2.628.77 5.421 6.468 5.557a5.945 5.945 0 004.227-1.3 5.651 5.651 0 013.9-1.141 5.616 5.616 0 013.889 1.141 5.832 5.832 0 003.941 1.3h.277c5.7-.136 6.463-2.929 6.463-5.557-.057-3.799-2.091-4.644-4.05-5.446z" data-sentry-element="path" data-sentry-source-file="PawWithLineIcon.tsx" />
        <path d="M12.919 10.8c2.215-.615 3.4-3.5 2.633-6.458S12.361-.508 10.16.12s-3.4 3.5-2.638 6.458 3.182 4.844 5.397 4.222zM21.091 10.8c2.215.629 4.637-1.277 5.4-4.224S26.079.735 23.846.12s-4.618 1.28-5.383 4.225.408 5.834 2.628 6.455zM5.637 18.085c2.211-.615 3.4-3.5 2.624-6.463S5.083 6.778 2.868 7.398-.53 10.909.23 13.856s3.192 4.844 5.407 4.229zM31.146 7.393c-2.211-.615-4.637 1.272-5.4 4.224s.422 5.848 2.638 6.463 4.628-1.267 5.4-4.224-.418-5.862-2.638-6.463z" data-sentry-element="path" data-sentry-source-file="PawWithLineIcon.tsx" />
      </g>
    </g>
  </SvgIcon>;