import reason1Icon from '../assets/images/app-reason-1.png';
import reason2Icon from '../assets/images/app-reason-2.png';
import reason3Icon from '../assets/images/app-reason-3.png';
import reason4Icon from '../assets/images/app-reason-4.png';

export const appReasons = [
  {
    heading: 'Here when you need us',
    subHeading: 'Whether you\'re looking for daily walks, planning a trip, stuck at work, or just want your best friend to have some company — we offer any day, anytime care.',
    iconURL: reason1Icon,
  },
  {
    heading: 'Safety is serious business',
    subHeading: 'Your dog\'s safety is our top priority. Every Pet Caregiver passes an enhanced background check, our services are insured, and support is standing by around the clock.',
    iconURL: reason2Icon,
  },
  {
    heading: 'All about convenience',
    subHeading: 'We\'ve thought of everything, so you don\'t have to. From GPS-tracked walks and customized lockboxes, to easy booking on the Wag app, we make your life easier at every step.',
    iconURL: reason3Icon,
  },
  {
    heading: 'We\'ve been around the block',
    subHeading: 'Wag! has a trusted record of experience with over 10M pet care services across 5,300 cities and counting. Our 150,000+ Pet Caregivers nationwide are dog people, and it shows.',
    iconURL: reason4Icon,
  },
];
