import React from 'react';
import { AppHeroHeaderSection } from './components/AppHeroHeaderSection';
import { StatesColumnListSection } from '../../components/StatesColumnListSection';
import { AppServicesSection } from './components/AppServicesSection';
import { AppReasonsToWagSection } from './components/AppReasonsToWagSection';
import { Container } from '../../wag-react/components/Container';
import { Divider } from '../../wag-react/core-components/divider';
export const AppPageContainer = () => <>
    <AppHeroHeaderSection data-sentry-element="AppHeroHeaderSection" data-sentry-source-file="index.tsx" />
    <AppServicesSection data-sentry-element="AppServicesSection" data-sentry-source-file="index.tsx" />
    <Container as="section" data-sentry-element="Container" data-sentry-source-file="index.tsx">
      <AppReasonsToWagSection data-sentry-element="AppReasonsToWagSection" data-sentry-source-file="index.tsx" />
      <Divider data-sentry-element="Divider" data-sentry-source-file="index.tsx" />
    </Container>
    <StatesColumnListSection data-sentry-element="StatesColumnListSection" data-sentry-source-file="index.tsx" />
  </>;