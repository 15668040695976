import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { system } from 'styled-system';
import { Box, BoxProps } from '../next-components/box';
type Props = BoxProps & JSX.IntrinsicElements['li'];

// TODO - Move to styled-system-adjunct for typings
const listStyle = system({
  listStyle: {
    property: 'listStyle'
  },
  listStyleType: {
    property: 'listStyleType'
  },
  listStylePosition: {
    property: 'listStylePosition'
  },
  listStyleImage: {
    property: 'listStyleImage'
  }
});
export const LI = styled(Box).attrs<Props>(() => ({
  as: 'li'
}))(listStyle) as unknown as FunctionComponent<React.PropsWithChildren<Props>>;
export type LIProps = Props;