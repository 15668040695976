import { Routes } from '../constants';

import { WalkIcon } from '../icons/services/WalkIcon';
import { SittingIcon } from '../icons/services/SittingIcon';
import { BoardingIcon } from '../icons/services/BoardingIcon';
import { TrainingIcon } from '../icons/services/TrainingIcon';
import { HealthIcon } from '../icons/services/HealthIcon';
import { DropInIcon } from '../icons/services/DropInIcon';

export const appServices = [
  {
    name: 'walking',
    label: 'Walking',
    icon: WalkIcon,
    url: Routes.DogWalkingArticleDirectory,
  },
  {
    name: 'sitting',
    label: 'Sitting',
    icon: SittingIcon,
    url: Routes.DogSitting,
  },
  {
    name: 'boarding',
    label: 'Boarding',
    icon: BoardingIcon,
    url: Routes.DogBoarding,
  },
  {
    name: 'training',
    label: 'Training',
    icon: TrainingIcon,
    url: Routes.DogTrainingArticleDirectory,
  },
  {
    name: 'health',
    label: 'Health',
    icon: HealthIcon,
    url: Routes.Health,
  },
  {
    name: 'drop-in',
    label: 'Drop-In',
    icon: DropInIcon,
    url: Routes.DropIn,
  },
];
