import React from 'react';
import { HeroHeaderSection } from '../../../components/HeroHeaderSection';
import { AppLandingPageAppLink } from '../../../constants/app';
import { Routes } from '../../../constants/routes';
import appHeaderBackgroundDesktop from '../../../assets/hero-header-backgrounds/app-desktop.jpg';
import appHeaderBackgroundMobile from '../../../assets/hero-header-backgrounds/app-mobile.png';
import { LazyImage } from '../../../wag-react/components/lazyload/LazyImage';
import { Placeholder as LazyImagePlaceholder } from '../../../wag-react/components/lazyload/Placeholder';
import { Box } from '../../../wag-react/next-components/box';
import { AppDownloadButton, Distribution } from '../../../wag-react/components/get-app-button';
export const AppHeroHeaderSection = () => <HeroHeaderSection as="section" backgroundImage={[`url(${appHeaderBackgroundMobile})`, null, `url(${appHeaderBackgroundDesktop})`]} height={['696px', '1200px', '548px']} titleText="Trusted Pet Caregivers are one tap away" titleTextProps={{
  maxWidth: ['316px', '632px']
}} ctaBlock={<Box display="grid" gridTemplateColumns="repeat(2, 1fr)" backgroundColor="rgba(255, 255, 255, 0.8)" borderRadius="12px" justifyItems={['center', null, 'unset']} maxWidth="460px">
        <LazyImage src={Routes.QrCode} width="104px" height="104px" imageProps={{
    width: '104px',
    height: '104px'
  }} my="24px" mx="24px" placeholder={<LazyImagePlaceholder width="104px" height="104px" />} />
        <Box gridTemplateColumns="1fr" justifyItems="center" alignItems="center" py="24px" pr="24px">
          <AppDownloadButton distribution={Distribution.AppStore} href={AppLandingPageAppLink.AppStore} width="192px" />
          <AppDownloadButton distribution={Distribution.PlayStore} href={AppLandingPageAppLink.PlayStore} width="192px" />
        </Box>
      </Box>} data-sentry-element="HeroHeaderSection" data-sentry-component="AppHeroHeaderSection" data-sentry-source-file="AppHeroHeaderSection.tsx" />;