import React from 'react';
import { Helmet } from '../../components/Helmet';
import { Layout } from '../../components/Layout';
import { AppPageContainer } from '../../containers/AppPageContainer';
import { useCanonicalUrl } from '../../hooks/useCanonicalUrl';
const Index = () => {
  const {
    canonicalUrl
  } = useCanonicalUrl();
  return <>
      <Helmet canonical={canonicalUrl} description="The #1 Wag! app connects you to vetted, local pet care professionals. Find dog walkers, dog sitters, dog trainers and 24/7 vet advice." title="Wag! App | Top Rated, On-Demand Pet Care" robots="index, follow" openGraph={{
      title: 'Wag! App | Top Rated, On-Demand Pet Care',
      description: 'The #1 Wag! app connects you to vetted, local pet care professionals. Find dog walkers, dog sitters, dog trainers and 24/7 vet advice.'
    }} data-sentry-element="Helmet" data-sentry-source-file="index.tsx" />
      <Layout data-sentry-element="Layout" data-sentry-source-file="index.tsx">
        <AppPageContainer data-sentry-element="AppPageContainer" data-sentry-source-file="index.tsx" />
      </Layout>
    </>;
};
export default Index;