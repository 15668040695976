import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { SectionHeading } from '../../../components/SectionHeading';
import { Container } from '../../../components/Container';
import { Link } from '../../../components/Link';
import { WaveBackground } from '../../../components/WaveBackground';
import { appServices } from '../../../data/app-services';
import waveBackgroundMobile from '../../../assets/waves/app-services-section-background-mobile.svg';
import waveBackgroundTablet from '../../../assets/waves/app-services-section-background-tablet.svg';
import waveBackgroundDesktop from '../../../assets/waves/app-services-section-background-desktop.svg';
import { Typography } from '../../../wag-react/components/Typography';
import { Box, BoxProps } from '../../../wag-react/next-components/box';
export const AppServicesSection = () => <WaveBackground as="section" backgroundImage={[`url(${waveBackgroundMobile})`, `url(${waveBackgroundTablet})`, `url(${waveBackgroundDesktop})`]} minHeight={['304px', '700px', '584px']} py="72px" data-sentry-element="WaveBackground" data-sentry-component="AppServicesSection" data-sentry-source-file="AppServicesSection.tsx">
    <SectionHeading data-sentry-element="SectionHeading" data-sentry-source-file="AppServicesSection.tsx">
      {'We\'ve got you covered'}
    </SectionHeading>
    <Container display="flex" flexDirection={['column', 'row', 'row']} alignItems="center" justifyContent="center" flexWrap="wrap" gridGap="24px" mt="72px" px={0} data-sentry-element="Container" data-sentry-source-file="AppServicesSection.tsx">
      {appServices.map(({
      name,
      label,
      url,
      icon: IconComponent
    }) => <ScalableElement key={name} as={Link} href={url} target="_blank" display="flex" flexDirection="column" alignItems="center" justifyContent="flex-end" backgroundColor="white" borderRadius="8px" height="180px" width={['180px', '164px', '164px', '180px']} transition="transform .2s">
          <IconComponent fontSize="80px" />
          <Typography fontWeight="700" // TODO - use theme?
      fontSize="20px" color="#727272">
            {label}
          </Typography>
        </ScalableElement>)}
    </Container>
  </WaveBackground>;
const ScalableElement = styled(Box)`
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  * {
    &:hover {
      cursor: pointer;
    }
  }
` as FunctionComponent<React.PropsWithChildren<BoxProps>>;