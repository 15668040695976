import React, { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';
import { Link as WagLink } from '../../wag-react/core-components/link';
import { StyledSystemProps } from '../../types';
export type LinkProps = Pick<JSX.IntrinsicElements['a'], 'title'> & {
  children: ReactNode;
  href: string;
  target?: string;
  style?: CSSProperties;
  rel?: string;
} & StyledSystemProps;
export const Link = ({
  children,
  href,
  target,
  ...rest
}: LinkProps) => <WagLink color="wagGreen" href={href} target={target} {...rest} data-sentry-element="WagLink" data-sentry-component="Link" data-sentry-source-file="index.tsx">
    {children}
  </WagLink>;